<template src="./RecommendModal.html">
</template>

<script>
import recommendationsService from "@/services/Recommendations";
export default {
  name: 'recommend-modal',
  props: {
    openRecommendModal: { type: Boolean, default: false },
    userId: { type: String },
    idRecommend: { type: String },
    groupId: { type: String }
  },
  data() {
    return {
      recommendation: ''
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeTheRecommendModal')
    },
    async createRecommendation() {
      try {
        const obj = {
          userId: Number(this.idRecommend),
          recommendation: this.recommendation,
          groupId: Number(this.groupId)
        }
      await recommendationsService.postRecommendation(obj);
        this.$buefy.toast.open({
          duration: 5000,
          position: "is-bottom",
          message: "Se hizo la recomendación con éxito",
          type: "is-success"
        });
        this.closeModal();
        this.recommendation = '';
      } catch (e) {
        console.error(e);
        this.$buefy.toast.open({
          duration: 5000,
          position: "is-bottom",
          message: e.response?.data?.message ?? 'Ocurrio un error.',
          type: "is-danger"
        });
        this.closeModal();
        this.recommendation = '';
      }
    }
  }
}
</script>

<style lang="scss" scoped src="./RecommendModal.scss">
</style>
