<template src="./DeleteGroupModal.html">
</template>

<script>
import idiService from "@/services/Idi";

  export default {
    name: 'delete-group-modal',
    props: {
      openDeleteGroupModal: { type: Boolean, defaul: false },
      groupId: { type: String }
    },
    methods: {
      groupDelete() {
        idiService.deleteGroup(this.groupId)
          .then(() => {
            this.$buefy.toast.open({
              duration: 5000,
              position: "is-bottom",
              message: "Se eliminó el grupo con éxito",
              type: "is-success"
            });
            this.closeModal()
            this.$router.push('/')
          })
          .catch(error => {
            console.error(error)
            this.$buefy.toast.open({
              duration: 5000,
              position: "is-bottom",
              message: "Falló la eliminación del grupo.",
              type: "is-error"
            });
          })
          .finally(this.closeModal());
      },
      closeModal() {
        this.$emit('closeTheDeleteGroupModal');
      }
    }
  }
</script>

<style lang="scss" scoped src="./DeleteGroupModal.scss">

</style>
