<template src="./ResearchGroup.html"></template>

<script>
import idiService from "@/services/Idi";
import EditGroupModal from "./EditGroupModal/EditGroupModal";
import DeleteGroupModal from "./DeleteGroupModal/DeleteGroupModal";
import RecommendModal from "./RecommendModal/RecommendModal";
import debounce from "lodash/debounce";
import { PROFILE_IMAGEN } from "@/utils/constants";
import { db } from "@/plugins/firebase";
import { roleName } from "@/utils/constants";
import historyService from "@/services/History";
import userService from "@/services/User";
import LeaveResearchGroupModal from "./LeaveResearchGroup/LeaveResearchGroup";
import ChosenUniversity from "../../components/ChosenUniversity/ChosenUniversity";
import notificationsService from "../../services/Notifications";
import { required, sameAs } from "vuelidate/lib/validators";
import moment from "moment";
import ModalDescriptionFile from "../../components/ModalDescriptionFile/ModalDescriptionFile";
import likeCommentService from "../../services/LikeAndComment";
import tutorial from "@/mixins/tutorial";

export default {
  name: "research-group",
  mixins: [tutorial],
  components: {
    EditGroupModal,
    DeleteGroupModal,
    RecommendModal,
    LeaveResearchGroupModal,
    ChosenUniversity,
    ModalDescriptionFile,
  },
  data() {
    return {
      group: { category: {} },
      participantSearchExpression: null,
      participantSearch: null,
      participant: null,
      participants: [],
      fileToLoad: null,
      groupToEdit: null,
      openEditModal: false,
      openDeleteGroupModal: false,
      openRecommendModal: false,
      userToRecommend: null,
      category: false,
      participation: {
        groupCoordinator: [],
      },
      groupId: null,
      userId: null,
      chats: [],
      chatMessages: [],
      chatMessage: null,
      chatId: null,
      chatHistory: false,
      publications: null,
      PROFILE_IMAGEN,
      roleName,
      openLeaveGroupModal: false,
      infoGroupLeave: null,
      role: "",
      modalCloseGroup: false,
      showButtonForDeleteFile: false,
      numberApplicants: 0,
      modalShareGroup: false,
      modalCreateEvent: false,
      infoCreateEvent: {},
      modalCloseTeacherResearcher: false,
      reasonClosingResearchGroup: "",
      wordClose: "CERRAR",
      modalSendRequest: false,
      confirmationWordClose: "",
      showModalImageDescription: false,
      ratingQualification: {},
    };
  },
  validations: {
    reasonClosingResearchGroup: { required },
    confirmationWordClose: {
      sameConfirmationWordClose: sameAs("wordClose"),
    },
  },
  created() {
    const user = this.$store.getters.getUser;
    this.userId = user.id;
    this.chatId = this.$route.params.id;
    this.validateCode();
  },
  mounted() {
    db.collection("rooms")
      .doc(this.chatId)
      .set(
        {
          users: [this.$store.state.User.user.id],
        },
        { merge: true }
      );

    this.chatListener = db
      .collection("rooms")
      .doc(this.chatId)
      .collection(this.chatId)
      .orderBy("timestamp", "desc")
      .limit(50)
      .onSnapshot((querySnapshot) => {
        querySnapshot.docChanges().forEach((message) => {
          if (message.type === "added") {
            const messageData = message.doc.data();

            this.chatHistory
              ? this.setNewMessage(messageData)
              : this.setChatHistory(messageData);
          }
        });

        this.chatHistory = true;
        this.scrollToEnd();
      });
    this.role = this.$store.state.User.user.role.name;
    this.dataTutorial("researchGroup");
  },
  updated() {
    this.scrollToEnd();
  },
  beforeUnmount() {
    this.chatListener();
  },
  methods: {
    customLabel(val) {
      if (val) return `${val.name} ${val.lastName}`;
    },
    async validateCode() {
      const validate = await idiService.userGroupParticipant(
        this.$route.params.id
      );
      await this.getInformation();
      if (
        validate.participant ||
        this.role === "University" ||
        this.role === "Administrator"
      ) {
        this.$buefy.toast.open({
          duration: 5000,
          position: "is-bottom",
          message: "Ingresando a grupo!",
          type: "is-success",
        });
      } else {
        this.$buefy.toast.open({
          duration: 5000,
          position: "is-bottom",
          message: "No posees permiso para ingresar",
          type: "is-danger",
        });
        this.$router.go(-1);
      }
    },
    scrollToEnd() {
      if (this.role !== "University") {
        let container = this.$el.querySelector("#chat-area");
        if (container) {
          container.scrollTop = container.scrollHeight;
        }
      }
    },
    async getInformation() {
      try {
        this.groupId = this.$route.params.id;
        this.group = await idiService.listOneGroup(this.groupId);
        const history = {
          title: `${this.group.name}`,
          description: `Has ingresado a un grupo de I+D+i ${this.group.name}`,
          url: "/idi",
          district: "I+D+I",
        };
        await historyService.createHistory(history);
        this.publications = await idiService.getGroupPublications(this.groupId);
        this.participation = await idiService.groupParticipantsAndRol();
        this.participants = await idiService.listGroupParticipants(
          this.groupId
        );
        this.numberApplicants = await idiService.countGroupParticipantRequest(
          this.groupId
        );
        const objQualification = {
          typePost: "group",
          post: this.groupId,
        };
        this.ratingQualification = await likeCommentService.qualification(
          objQualification
        );
        this.$nextTick();
      } catch (error) {
        console.error(error);
        this.$buefy.toast.open({
          duration: 5000,
          message: error.response.data.message,
          position: "is-bottom",
          type: "is-danger",
        });
      }
    },
    async emitChatMessage() {
      try {
        if (this.chatMessage.length) {
          const timestamp = new Date().getTime().toString();

          db.collection("rooms")
            .doc(this.chatId)
            .collection(this.chatId)
            .doc(timestamp)
            .set({
              idFrom: this.$store.state.User.user.id,
              image: this.$store.state.User.user.photo
                ? this.$store.state.User.user.photo
                : this.PROFILE_IMAGEN,
              timestamp,
              name: this.$store.state.User.user.name,
              content: this.chatMessage,
            })
            .then(() => {
              this.chatMessage = "";
            });
        }
      } catch (error) {
        console.error("Error sending the chat message", error);
      }
    },
    searchExpression: debounce(function (name) {
      if (!name.length) {
        this.participantSearch = null;
        this.participant = null;
        return;
      }
      setTimeout(async () => {
        try {
          const data = await userService.searchUsersWithDiferentRole(
            this.roleName.ADMINISTRATOR,
            this.participantSearchExpression
          );
          this.participantSearch = [];
          data.forEach((item) => this.participantSearch.push(item));
        } catch (error) {
          console.error(error);
        }
      });
    }, 300),
    openTheEditGroupModal() {
      this.groupToEdit = {
        name: this.group.name,
        description: this.group.description,
        category: this.group.category,
        registrationNumber: this.group.registrationNumber,
        coordinatorId: this.group.coordinator.id,
        institutionName: this.group.institutionName,
        institutionId: this.group.institutionId,
        groupId: this.group.id,
      };
      this.openEditModal = true;
    },
    openTheDeleteGroupModal() {
      this.openDeleteGroupModal = true;
    },
    openTheRecommendModal(userId) {
      this.openRecommendModal = true;
      this.userToRecommend = userId;
    },
    openTheLeaveGroupModal() {
      this.infoGroupLeave = {
        userId: this.userId,
        groupId: this.$route.params.id,
      };
      this.openLeaveGroupModal = true;
    },
    closeTheEditGroupModal() {
      this.groupToEdit = null;
      this.openEditModal = false;
    },
    closeTheDeleteGroupModal() {
      this.openDeleteGroupModal = false;
    },
    closeTheRecommendModal() {
      this.openRecommendModal = false;
      this.userToRecommend = null;
    },
    async closeTheEditGroupModalAndRefresh() {
      this.groupToEdit = null;
      this.openEditModal = false;
      const [groupInfo] = await idiService.listOneGroup(this.groupId);
      this.groupName = groupInfo.name;
      this.groupDescription = groupInfo.description;
    },
    closeTheLeaveGroupModal() {
      this.openLeaveGroupModal = false;
    },
    async postParticipant() {
      try {
        await idiService.postNewParticipant(this.groupId, this.participant.id);
        this.participants = await idiService.listGroupParticipants(
          this.groupId
        );
        this.participant = null;
        this.participantSearch = null;
        this.participantSearchExpression = null;
        this.$buefy.toast.open({
          duration: 5000,
          position: "is-bottom",
          message: "Se agregó un participante con éxito.",
          type: "is-success",
        });
      } catch (e) {
        console.error(e);
        this.$buefy.toast.open({
          duration: 5000,
          position: "is-bottom",
          message: e.response.data.message ?? "Ocurrio un error",
          type: "is-danger",
        });
      }
    },
    async deleteParticipant(id) {
      try {
        await idiService.deleteParticipant({
          groupId: this.groupId,
          userId: id,
        });
        this.$buefy.toast.open({
          duration: 5000,
          position: "is-bottom",
          message: "El participante se eliminó con éxito.",
          type: "is-success",
        });
        this.participants = await idiService.listGroupParticipants(
          this.groupId
        );
      } catch (e) {
        console.error(e);
        this.$buefy.toast.open({
          duration: 5000,
          position: "is-bottom",
          message: e.response.data.message ?? "Ocurrio un error",
          type: "is-danger",
        });
      }
    },
    async deletePublication(id) {
      try {
        await idiService.deletePublication(id);
        this.$buefy.toast.open({
          duration: 5000,
          position: "is-bottom",
          message: "La publicación se eliminó con éxito",
          type: "is-success",
        });
        this.publications = await idiService.getGroupPublications(this.groupId);
      } catch (e) {
        console.error(e);
        this.$buefy.toast.open({
          duration: 5000,
          position: "is-bottom",
          message: e.response.data.message ?? "Ocurrio un error",
          type: "is-danger",
        });
      }
    },
    getIntoMeetIdi() {
      this.$router.push({
        name: "IdiMeet",
        params: { idEvent: this.groupId },
      });
    },
    setChatHistory(messageData) {
      this.chatMessages.unshift({
        message: messageData.content,
        name: messageData.name,
        image: messageData.image,
        userSenderId: messageData.idFrom,
      });
    },
    setNewMessage(messageData) {
      this.chatMessages.push({
        message: messageData.content,
        name: messageData.name,
        image: messageData.image,
        userSenderId: messageData.idFrom,
      });
    },
    showModalCloseGroup() {
      this.modalCloseGroup = true;
    },
    deleteFileButton(index) {
      this.showButtonForDeleteFile = index;
    },
    showButtonDeleteFile(index) {
      if (index === this.showButtonForDeleteFile) {
        return true;
      } else {
        return false;
      }
    },
    buttonBackDeleteFile() {
      this.showButtonForDeleteFile = false;
    },
    showModalShareGroup() {
      this.modalShareGroup = true;
    },
    async share() {
      try {
        const infoShare = {
          title: "Te han compartido un grupo de investigación",
          body: this.group.name,
          typeId: this.group.category.id,
        };
        await notificationsService.shareAnnouncement(infoShare);
        this.modalShareAnnouncement = false;
      } catch (error) {
        console.error(error);
      }
    },
    showModalTeacherResearcher() {
      this.modalCloseTeacherResearcher = true;
    },
    async sendRequest() {
      try {
        const infoChange = {
          typeRequest: "delete",
          groupUpdate: {
            name: this.group.name,
            categoryId: this.group.category.id,
            coordinatorId: this.userId,
            registrationNumber: this.group.registrationNumber ? this.group.registrationNumber : "",
            institutionId: this.group.institutionId,
            institutionName: this.group.institutionName,
            description: this.group.description,
            fileImg: this.group.fileImg,
            descriptionImg: this.group.descriptionImg,
          },
          groupId: this.group.id,
          userId: this.userId,
          reason: this.reasonClosingResearchGroup,
        };
        await idiService.groupChangeRequest(infoChange);
        this.$buefy.toast.open({
          duration: 5000,
          position: "is-bottom",
          message: "Solicitud enviada con éxito",
          type: "is-success",
        });
        this.modalCloseTeacherResearcher = false;
        this.modalSendRequest = true;
      } catch (error) {
        console.error(error);
        this.$buefy.toast.open({
          duration: 5000,
          message: error.response.data.message,
          position: "is-bottom",
          type: "is-danger",
        });
      }
    },
    async deleteGroup(){
      try {
        await idiService.deleteGroup(this.groupId);
        this.$buefy.toast.open({
          duration: 5000,
          position: "is-bottom",
          message: "El grupo se eliminó con éxito",
          type: "is-success",
        });
        this.$router.push({ name: "Idi" });
      } catch (error) {
        console.error(error);
        this.$buefy.toast.open({
          duration: 5000,
          message: error.response.data.message,
          position: "is-bottom",
          type: "is-danger",
        });
      }
    },
    async createEvent() {
      try {
        const obj = {
          group: Number(this.$route.params.id),
          name: this.infoCreateEvent.name,
          description: this.infoCreateEvent.description,
          date: moment(this.infoCreateEvent.dateInit).format(),
          startTime: moment(this.infoCreateEvent.startHour).format(),
          endTime: moment(this.infoCreateEvent.finishHour).format(),
        };
        await idiService.scheduleMeeting(obj);
        this.$buefy.toast.open({
          duration: 5000,
          position: "is-bottom",
          message: "Evento creado con éxito",
          type: "is-success",
        });
        this.modalCreateEvent = false;
        this.infoCreateEvent = {};
      } catch (error) {
        console.error(error);
        this.$buefy.toast.open({
          duration: 5000,
          message: error.response.data.message,
          position: "is-bottom",
          type: "is-danger",
        });
      }
    },
    openModalImageDescription() {
      this.showModalImageDescription = true;
    },
    closeModalDescriptionImage() {
      this.showModalImageDescription = false;
    },
    uploadFileInfo(info) {
      this.fileToLoad = info;
      this.showModalImageDescription = false;
    },
  },
  computed: {
    validateCoordinator() {
      const groupId = this.$route.params.id;
      let coordinatorValidation = false;
      if (this.participation) {
        coordinatorValidation = this.participation.groupCoordinator.some(
          (group) => group == groupId
        );
      }
      return coordinatorValidation;
    },
    roleValidationUniversity() {
      return ["University"].some((a) => a === this.role);
    },
    roleValidationTeacherInvestigator() {
      return ["TeacherInvestigator"].some((a) => a === this.role);
    },
    roleValidation() {
      return ["Student", "Teacher"].some((a) => a === this.role);
    },
    roleValidationAdmin() {
      return ["Administrator"].some((a) => a === this.role);
    },
    typeOfFile() {
      return (name) => {
        const ext = name.split(".").pop();
        switch (ext) {
          case "pdf":
            return "fa regular fa-file";
          case "png":
          case "jpg":
            return "fa fa-image";
        }
      };
    },
  },
  watch: {
    fileToLoad: async function (file) {
      this.$store.dispatch("fetchLoading", true);
      try {
        await idiService.putFile(
          "gruops",
          "files",
          `${file.file.name}`,
          file,
          this.$route.params.id,
          file.description
        );
        this.$buefy.toast.open({
          duration: 5000,
          position: "is-bottom",
          message: "Se subio el archivo con éxito.",
          type: "is-success",
        });
        this.publications = await idiService.getGroupPublications(
          this.$route.params.id
        );
      } catch (e) {
        this.$buefy.toast.open({
          duration: 5000,
          message: e.response.data.message ?? "Ocurrio un error",
          position: "is-bottom",
          type: "is-danger",
        });
      }
      this.$store.dispatch("fetchLoading", false);
    },
  },
};
</script>

<style lang="scss" scoped src="./ResearchGroup.scss">

</style>
